.close-button {
    position: absolute;
    /* top: 10px; */
    /* right: 10px; */
    background-color: #87b338;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 3px 13px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 675px;
}